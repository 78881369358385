const getInitialvalues = (filters) => {
  return {
    lead_status_id: filters.lead_status_id || [],
    source_id: filters.source_id || [],
    user_id: filters.user_id || [],
    followup_date_from: filters.followup_date_from || null,
    followup_date_to: filters.followup_date_to || null,
    created_at_from: filters.created_at_from || null,
    created_at_to: filters.created_at_to || null,
    contacted_date_from: filters.contacted_date_from || null,
    contacted_date_to: filters.contacted_date_to || null,
    followup_note_date_from: filters.followup_note_date_from || null,
    followup_note_date_to: filters.followup_note_date_to || null,
    assignee_date_from: filters.assignee_date_from || null,
    assignee_date_to: filters.assignee_date_to || null,

    preferred_countries: filters.preferred_countries || [],
    ssc_passing_year_from: filters.ssc_passing_year_from || null,
    ssc_passing_year_to: filters.ssc_passing_year_to || null,
    ssc_gpa_from: filters.ssc_gpa_from || null,
    ssc_gpa_to: filters.ssc_gpa_to || null,
    ssc_group: filters.ssc_group || null,
    ssc_board: filters.ssc_board || null,
    hsc_passing_year_from: filters.hsc_passing_year_from || null,
    hsc_passing_year_to: filters.hsc_passing_year_to || null,
    hsc_gpa_from: filters.hsc_gpa_from || null,
    hsc_gpa_to: filters.hsc_gpa_to || null,
    hsc_group: filters.hsc_group || null,
    hsc_board: filters.hsc_board || null,
    bachelor_passing_year_from: filters.bachelor_passing_year_from || null,
    bachelor_passing_year_to: filters.bachelor_passing_year_to || null,
    bachelor_gpa_from: filters.bachelor_gpa_from || null,
    bachelor_gpa_to: filters.bachelor_gpa_to || null,
    bachelor_subject_id: filters.bachelor_subject_id || [],
    bachelor_university_id: filters.bachelor_university_id || [],
    master_passing_year_from: filters.master_passing_year_from || null,
    master_passing_year_to: filters.master_passing_year_to || null,
    master_gpa_from: filters.master_gpa_from || null,
    master_gpa_to: filters.master_gpa_to || null,
    master_subject_id: filters.master_subject_id || [],
    master_university_id: filters.master_university_id || [],
    o_level_passing_year_from: filters.o_level_passing_year_from || null,
    o_level_passing_year_to: filters.o_level_passing_year_to || null,
    o_level_grade: filters.o_level_grade || [],
    o_level_institute_id: filters.o_level_institute_id || [],
    a_level_passing_year_from: filters.a_level_passing_year_from || null,
    a_level_passing_year_to: filters.a_level_passing_year_to || null,
    a_level_grade: filters.a_level_grade || [],
    a_level_institute_id: filters.a_level_institute_id || [],
    course_id: filters.course_id || null,
    overall_from: filters.overall_from || null,
    overall_to: filters.overall_to || null,
    literacy_from: filters.literacy_from || null,
    literacy_to: filters.literacy_to || null,
    comprehension_from: filters.comprehension_from || null,
    comprehension_to: filters.comprehension_to || null,
    conversation_from: filters.conversation_from || null,
    conversation_to: filters.conversation_to || null,
    production_from: filters.production_from || null,
    production_to: filters.production_to || null,
    listening_from: filters.listening_from || null,
    listening_to: filters.listening_to || null,
    reading_from: filters.reading_from || null,
    reading_to: filters.reading_to || null,
    writing_from: filters.writing_from || null,
    writing_to: filters.writing_to || null,
    speaking_from: filters.speaking_from || null,
    speaking_to: filters.speaking_to || null,
  };
};

export default getInitialvalues;
