import { AiOutlineProject, AiOutlineSolution } from "react-icons/ai";
import { BiTask } from "react-icons/bi";
import { LuHelpCircle } from "react-icons/lu";
import {
  MdDashboard,
  MdOutlineAccountBalanceWallet,
  MdOutlineSupportAgent,
  MdPayment,
} from "react-icons/md";
import { RiFileList3Line } from "react-icons/ri";
import { TbReport, TbUserCheck, TbUserSearch, TbUsers } from "react-icons/tb";
import { VscTrash } from "react-icons/vsc";

const mainMenu = (componentsToRender = []) => {
  const menuToRender = [];
  const isIncludes = (path_name) => {
    const paths = path_name?.split("/");
    // const firstPath = paths[1];
    const lastPath = paths[paths.length - 1];
    return componentsToRender?.includes(lastPath);
  };
  menuList?.map((singleMenu) => {
    if (singleMenu?.path === "/") {
      if (isIncludes("/dashboard")) menuToRender.push(singleMenu);
    } else if (singleMenu?.child) {
      const childsToRender = singleMenu?.child?.filter((singleChild) =>
        isIncludes(singleChild?.path)
      );
      if (childsToRender?.length)
        menuToRender.push({ ...singleMenu, child: childsToRender });
    } else {
      if (isIncludes(singleMenu?.path)) menuToRender.push(singleMenu);
    }
    return null;
  });
  return menuToRender;
};

export default mainMenu;

const menuList = [
  {
    label: "Dashboard",
    path: "/",
    icon: <MdDashboard />,
  },
  {
    label: "Leads",
    path: "/leads",
    icon: <TbUserSearch />,
  },

  {
    label: "Customers",
    path: "/customers",
    icon: <TbUsers />,
  },
  {
    label: "Projects",
    path: "/projects",
    icon: <AiOutlineProject />,
  },
  {
    label: "Revenue Records",
    path: "/revenue-records",
    icon: <RiFileList3Line />,
    child: [
      {
        label: "Invoices",
        path: "/revenue-records/invoices",
      },
      {
        label: "Commissions",
        path: "/revenue-records/commissions",
      },
      {
        label: "Bank Invoices",
        path: "/revenue-records/bank-invoices",
      },
      {
        label: "Subscription",
        path: "/revenue-records/sales",
      },
    ],
  },
  {
    label: "Payments",
    path: "/payments",
    icon: <MdPayment />,
  },
  {
    label: "Expenses",
    path: "/expenses",
    icon: <MdOutlineAccountBalanceWallet />,
  },
  {
    label: "Tasks",
    path: "/tasks",
    icon: <BiTask />,
  },
  {
    label: "Attendance",
    path: "/attendance",
    icon: <TbUserCheck />,
  },

  {
    label: "Reports",
    path: "/reports",
    icon: <TbReport />,
    child: [
      {
        label: "University Targets",
        path: "/reports/university-targets",
      },
      {
        label: "Lead Assigned",
        path: "/reports/report-lead-assigned",
      },
    ],
  },
  {
    label: "Utilities",
    path: "/utilities",
    icon: <AiOutlineSolution />,
    child: [
      {
        label: "Attachments",
        path: "/utilities/attachments",
      },
      {
        label: "Notes",
        path: "/utilities/notes",
      },
      {
        label: "Activity Log",
        path: "/utilities/activity-log",
      },
      {
        label: "Backup",
        path: "/utilities/backup",
      },
    ],
  },
  {
    label: "Support",
    path: "/support",
    icon: <LuHelpCircle />,
    child: [
      {
        label: "Knowledge",
        path: "/support/knowledge",
      },
    ],
  },
  {
    label: "Tickets",
    path: "/tickets",
    icon: <MdOutlineSupportAgent />,
  },
  {
    label: "Trash",
    path: "/trash",
    icon: <VscTrash />,
    child: [
      {
        label: "Leads",
        path: "/trash/leads",
      },
      {
        label: "Customers",
        path: "/trash/customers",
      },
      {
        label: "Projects",
        path: "/trash/projects",
      },
      {
        label: "Invoices",
        path: "/trash/invoices",
      },
      {
        label: "Expenses",
        path: "/trash/expenses",
      },
    ],
  },
];
