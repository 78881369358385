const getInitialvalues = (filters) => {
  return {
    user_id: filters.user_id || [],
    laed_id: filters.laed_id || [],
    created_at_from: filters.created_at_from || null,
    created_at_to: filters.created_at_to || null,
  };
};

export default getInitialvalues;
