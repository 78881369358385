import { TableCell, TableRow } from "@mui/material";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  selectedRows,
  handleTableCheckBox,
  permissions,
  ...restProps
}) => {
  return (
    <TableRow
      {...restProps}
      hover
      role="checkbox"
      key={row.id}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "20px 0px 20px 20px",
        },
      }}
    >
      {columns.map((column, i) => {
        const value = row[column.id];

        return (
          <TableCell key={column.id} align={column.align}>
            {value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
