import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

const Summary = ({
  filters,
  search,
  setFilters,
  pagination,
  setPagination,
  setReload,
  activeMenu,
  setActiveMenu,
}) => {
  const [leadSummary, setLeadSummary] = useState([]);
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getLeadSummary = async () => {
      const endpoint = {
        method: "post",
        url: "api/admin/lead/summery",
        data: { ...filters, search },
      };
      const result = await fetchData(endpoint, false);
      setLeadSummary(result?.data || {});
    };
    getLeadSummary();
  }, [search, filters]);

  const handleSummaryClick = (type, id) => {
    let newFilters = { ...filters };
    if (type === "status") {
      newFilters.lead_status_id = [id];
    } else if (type === "source") {
      newFilters.source_id = [id];
    }
    setFilters({ ...filters, ...newFilters });
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
    setActiveMenu(activeMenu === "summary" ? "" : "summary");
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 145px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              padding: "20px 20px",
            }}
          >
            {leadSummary?.status?.length > 0 &&
              leadSummary?.status.map((singleStatus, i) => (
                <Box
                  key={i}
                  sx={{
                    bgcolor: `${singleStatus?.color}27`,
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "2px 1px 3px #e5e5e5",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: `${singleStatus?.color}37`,
                    },
                  }}
                  onClick={() =>
                    handleSummaryClick("status", singleStatus.lead_status_id)
                  }
                >
                  <Typography>{singleStatus?.name}</Typography>
                  <Typography variant="h3">
                    {singleStatus?.total_lead}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box sx={{ borderBottom: "1px solid #ddd", margin: "0 20px" }}></Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              padding: "20px",
            }}
          >
            {leadSummary?.source &&
              leadSummary?.source.map((singleSource, i) => (
                <Box
                  key={i}
                  sx={{
                    bgcolor: "#fff",
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "2px 1px 3px #e5e5e5",
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: `#fff6`,
                    },
                  }}
                  onClick={() =>
                    handleSummaryClick("source", singleSource.source_id)
                  }
                >
                  <Typography>{singleSource?.name}</Typography>
                  <Typography variant="h3">
                    {singleSource?.total_lead}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Summary;
