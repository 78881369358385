import Button from "@mui/material/Button";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApiCall from "hooks/useApiCall";
import useBase from "hooks/useBase";
import usePermissions from "hooks/usePermissions";
import useQueries from "hooks/useQueries";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import checkFiltering from "utils/checkFiltering";
import { deleteLead, viewAllLeads } from "../api/leads";
import LeadsBulkActions from "./BulkActions";
import Header from "./Header";
import Import from "./Import";
import Summary from "./Summary";
import AddEdit from "./addEdit/AddEdit";
import AdvanceFilter from "./filter/AdvanceFilter";
import MainScreenFilter from "./filter/MainScreenFilter";
import MainTable from "./table/MainTable";
import ViewLead from "./viewLead/ViewLead";
import useDidMountEffect from "hooks/useDidMountEffect";

const Leads = () => {
  const { setQueries, openAddEdit, openView } = useQueries();
  const [persistedData, setPersistedData] = useState({});
  const { isMini } = useWindowSize();
  const { updateBase, base } = useBase();
  const [activeMenu, setActiveMenu] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [openImportLead, setOpenImportLead] = useState(false);
  const [allLeads, setAllLeads] = useState([]);
  // Helper states
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });


  const isFiltering = checkFiltering(filters);

  const { data, loading, fetchData: fetchListData } = useApiCall();

  useEffect(() => {
    updateBase([
      "leadStatuses",
      "sources",
      "assignees",
      "countries",
      "institutes",
      "universities",
      "subjects",
      "smsTemplates",
    ]);
  }, [reload]);

  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;

    fetchListData(
      viewAllLeads,
      {
        body_data: finalFilters,
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, search, pagination?.current_page, pagination?.per_page]);

  useDidMountEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllLeads(allData);
      setPagination(rest);
      setSelectedRows([]);
    }
  }, [data]);

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    if (allLeads?.length > 1) {
      setAllLeads(allLeads.filter((lead) => lead?.id !== openAlert));
      setOpenAlert(false);
      try {
        const result = await deleteLead({ id: openAlert });
        if (result.success) {
          toast.success(result?.message);
        } else {
          toast.error(result?.message);
        }
      } catch (error) {}
    } else {
      setOpenAlert(false);
      const result = await deleteLead({ id: openAlert });
      if (result.success) {
        toast.success(result?.message);
      } else {
        toast.error(result?.message);
      }
      setReload((pre) => !pre);
    }
    setSelectedRows([]);
  };

  const permissions = usePermissions("lead");



  const all_props = {
    ...base,
    setPersistedData,
    persistedData,
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allLeads,
    setAllLeads,
    permissions,
  };

  // Bulk options
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTableCheckBox = (id, checked) => {
    if (id === "all") {
      if (checked) {
        setSelectedRows(allLeads?.map((lead) => lead?.id));
      } else {
        setSelectedRows([]);
      }
    } else {
      if (checked) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows(selectedRows?.filter((lead) => lead !== id));
      }
    }
  };

  return (
    <div>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete lead"
          desc="Are you sure you want to delete? Don't worry you will find this lead on Trash Menu"
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Header
        {...{
          activeMenu,
          setActiveMenu,
          setOpenFilter,
          setOpenImportLead,
          setSearch,
          setPagination,
          pagination,
          permissions,
          isFiltering,
          search,
          filters,
        }}
      />
      {activeMenu === "summary" && (
        <Summary
          filters={filters}
          search={search}
          setFilters={setFilters}
          pagination={pagination}
          setPagination={setPagination}
          setReload={setReload}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      )}
      {openImportLead && (
        <Import
          setOpenDrawer={setOpenImportLead}
          openDrawer={openImportLead}
          {...all_props}
        />
      )}
      {openFilter && (
        <AdvanceFilter
          openDrawer={openFilter}
          setOpenDrawer={setOpenFilter}
          {...all_props}
        />
      )}
      {openAddEdit && (
        <AddEdit
          {...{
            openDrawer: openAddEdit,
            setOpenDrawer: () => setQueries(""),
            ...all_props,
          }}
        />
      )}
      {openView && (
        <ViewLead
          openDrawer={openView}
          setOpenDrawer={() => setQueries("")}
          {...all_props}
        />
      )}
      {!isMini &&
        !activeMenu &&
        !selectedRows?.length > 0 &&
        !!(permissions["list(own)"] || permissions["list(global)"]) && (
          <MainScreenFilter {...all_props} />
        )}
      {selectedRows?.length > 0 && (
        <LeadsBulkActions
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          {...all_props}
        />
      )}
      {!activeMenu &&
        (permissions["list(own)"] || permissions["list(global)"] ? (
          <MainTable
            handleDelete={handleDelete}
            loading={loading}
            handleTableCheckBox={handleTableCheckBox}
            selectedRows={selectedRows}
            {...all_props}
          />
        ) : (
          <NotAuthorized />
        ))}
    </div>
  );
};

export default Leads;
